import React from "react";

const Title = () => {
  return (
    <div className="btn m-auto" style={{ cursor: "default" }}>
      <h1>
        <span
          className="ms-1"
          style={{
            fontWeight: "bold",
            textShadow: "2px 2px 3px rgb(150, 150, 150)",
          }}
        >
          <a href="/" className="text-decoration-none text-white">BlogWise</a>
        </span>
        </h1>
    </div>
  );
};

export default Title;
