import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";



const PostThumbnail = (props) => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`https://picsum.photos/seed/${encodeURIComponent(props.post.title)}/400/300`);
        if (response.ok) {
          const data = await response.blob();
          setImageUrl(URL.createObjectURL(data));
        } else {
          console.error("Failed to fetch image:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [props.post.title]);

  return (
    <Link to={"/post/" + props.post.id} className="thumbnail text-decoration-none text-white d-block">
      <Card className="post-thumbnail">
      <div className="post-badge" style={{ position: 'absolute', margin: '1em' }}>
          <span className="badge bg-light text-dark post-author">{props.post.blogUser}</span>
        </div>
        <Row>
          <Col>
            {imageUrl && <Card.Img variant="top" src={imageUrl} alt={props.post.title} />}
          </Col>
        </Row>
        <Card.Body>
          <Card.Title>{props.post.title}</Card.Title>
          <Card.Text>{props.post.content.slice(0, 200) + " . . ."}</Card.Text>
        </Card.Body>
        <Card.Footer className="d-flex text-muted">
          <div className="me-auto">{`${t("posted")}: ${
            props.post.createdOn
          }`}</div>
          <div>{`${t("edited")}: ${props.post.updatedOn}`}</div>
        </Card.Footer>
      </Card>
    </Link>
  );
};

export default PostThumbnail;


