import React, { useState } from "react";
import PostThumbnail from "./PostThumbnail";
import { Row } from "react-bootstrap";

const PostThumbnailsList = (props) => {
  const postsPerPage = 9; // Number of posts to display per page
  const totalPages = Math.ceil(props.posts.length / postsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = props.posts.reverse().slice(indexOfFirstPost, indexOfLastPost);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <>
    {currentPosts.map((post, index) => (
      <Row key={index} className="mb-3">
        <PostThumbnail post={post} />
      </Row>
    ))}
    <div className="pagination">
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={index}
          className={index + 1 === currentPage ? "active" : ""}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </button>
      ))}
    </div>
  </>
  );
};

export default PostThumbnailsList;
